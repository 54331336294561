<template>
  <ModalComponent
    id="create-hardware-booking-modal"
    title="Crear booking"
    ok-title="Siguiente"
    size="lg"
    @confirmed="onCreateBooking"
    @show="onShow()"
  >
    <HardwareBookingForm
      ref="hardwareBookingForm"
      :form_prop.sync="form"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import HardwareBookingForm from '@/components/Business/Project/HardwareBooking/Form/HardwareBookingForm.vue'
import HardwareBookingFactory from '@/domain/services/HardwareBookingFactory'

export default {
  name: 'CreateHardwareBookingModal',
  components: {
    ModalComponent,
    HardwareBookingForm
  },
  data () {
    return {
      form: this.form_prop
    }
  },
  computed: {
    ...mapGetters({ hardwareBooking: 'hardwareBooking/current' })
  },
  methods: {
    ...mapActions('hardwareBooking', ['setEmpty']),
    onShow () {
      this.setEmpty()
      this.form = HardwareBookingFactory.get(this.hardwareBooking)
    },
    async onCreateBooking () {
      const valid = await this.$refs.hardwareBookingForm.$refs.hardwareBookingForm.validate()
      if (!valid) {
        return
      }
      const payload = {
        projectId: this.$route.params.projectId,
        data: this.form
      }
      this.$store.dispatch('hardwareBooking/create', payload)
        .then(() => {
          this.$bvModal.hide('create-hardware-booking-modal')
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'createHardwareBookingModal';
</style>
